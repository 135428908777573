<template>
  <v-app>
    <v-app-bar color="primary" flat height="80" max-height="80" tile>
      <v-spacer></v-spacer>
      <a href="#" class="text-center pa-5">
        <img src="/media/logos/logo_light_155x30.png" class="max-h-70px" alt />
      </a>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-content class="mb-12">
      <v-container class="mb-12">
        <v-container class="mb-12">
          <v-row justify="center">
            <v-col
              v-if="['update-app', 'update-ios-app'].includes($route.name)"
              md="6"
              cols="12"
            >
              <v-card outlined>
                <v-card-title>
                  <div>
                    <div class="font-size-h3 bold-text">Update our iOS app</div>
                    <div class="body-1">
                      To install your team releases and more
                    </div>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-img
                    :src="'https://testapp.io/images/TestApp.io---App.svg'"
                  ></v-img>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="mobile === 'unknown' || mobile === 'ios'"
                    color="primary"
                    :x-large="$vuetify.breakpoint.mdAndUp"
                    :class="$vuetify.breakpoint.mdAndUp ? 'pa-8' : ''"
                    class="text-transform-none bold-text font-size-h3"
                    @click="openDownloadLinks('appStore')"
                  >
                    <v-icon color="white" v-text="'mdi-apple'" left></v-icon>
                    Get our iOS
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              v-if="['update-app', 'update-android-app'].includes($route.name)"
              md="6"
              cols="12"
            >
              <v-card outlined>
                <v-card-title>
                  <div>
                    <div class="font-size-h3 bold-text">
                      Update our android app
                    </div>
                    <div class="body-1">
                      To install your team releases and more
                    </div>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-img
                    :src="'https://testapp.io/images/TestApp.io---App.svg'"
                  ></v-img>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="mobile === 'unknown' || mobile === 'android'"
                    color="primary"
                    :x-large="$vuetify.breakpoint.mdAndUp"
                    :class="$vuetify.breakpoint.mdAndUp ? 'pa-8' : ''"
                    class="text-transform-none bold-text font-size-h3"
                    @click="openDownloadLinks('playStore')"
                  >
                    <v-icon color="white" v-text="'mdi-android'" left></v-icon>
                    Get our Android
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-content>
    <k-t-footer></k-t-footer>
    <Modal
      v-model="showAndroidOption"
      @close="showAndroidOption = false"
      :width="$vuetify.breakpoint.smAndDown ? '' : '800'"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <template #message>
        <v-row>
          <v-col v-if="mobile === 'unknown' || mobile === 'android'" cols="12">
            <div class="mt-3 font-size-h3 bold-text">
              Get our app for Android
            </div>
            <div class="mb-5 body-1 bold-text">
              You can use following android stores to download our app.
              <br />
              If you need help please
              <span
                @click="onContactus"
                class="pointer blue--text text-decoration-underline"
                >contact us</span
              >
            </div>
            <v-row justify="space-around">
              <v-col cols="12" md="4">
                <a @click="openDownloadLinks('playStore')">
                  <img src="/media/svg/google-play.svg" class="install-img" />
                </a>
              </v-col>
              <v-col cols="12" md="4">
                <a @click="openDownloadLinks('huaweiStore')">
                  <img src="/media/svg/haweiistore.png" class="install-img" />
                </a>
              </v-col>
              <v-col cols="12" md="4">
                <a @click="openDownloadLinks('samsungStore')">
                  <img src="/media/svg/samsungstore.png" class="install-img" />
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal
      no-py
      :fullscreen="$vuetify.breakpoint.smAndDown"
      v-model="showDownloadModal"
      @close="showDownloadModal = false"
    >
      <template #message>
        <app-download-modal
          :link="selectedLink"
          :title="selectedTitle"
        ></app-download-modal>
      </template>
    </Modal>
  </v-app>
</template>

<style lang="scss">
.v-btn.install {
  width: 195px !important;
  height: 64px !important;
  border-radius: 10px !important;
  span {
    font-size: 19px !important;
    text-transform: capitalize;
    letter-spacing: 0;
  }
}
img.install-img {
  width: 195px !important;
  height: 64px !important;
}
</style>

<script>
import AppDownloadModal from "@/view/components/Common/AppDownloadModal.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";

export default {
  name: "install",
  components: { KTFooter, AppDownloadModal },
  data() {
    return {
      mobile: "",
      showAndroidOption: false,
      showIOSOption: false,
      selectedLink: "",
      selectedTitle: "",
      showDownloadModal: false,
      downloadLinks: {
        playStore: {
          link:
            "https://play.google.com/store/apps/details?id=testapp.io&hl=en_US&gl=US",
          title: "Google Play Store",
        },
        huaweiStore: {
          link: "https://appgallery.huawei.com/#/app/C103407753",
          title: "Huawei AppGallery",
        },
        samsungStore: {
          link: "https://galaxy.store/testappio",
          title: "Samsung Galaxy Store",
        },
        amazonStore: {
          link: "https://www.amazon.com/gp/product/B095RFPRNR",
          title: "Amazon AppStore",
        },
        appStore: {
          link: "https://apps.apple.com/us/app/testapp-io/id1518972541",
          title: "Apple App Store",
        },
      },
    };
  },
  computed: {},
  created() {
    this.mobile = this.getMobileOperatingSystem;
  },
  methods: {
    openDownloadLinks(device) {
      this.selectedTitle = this.downloadLinks[device].title;
      this.selectedLink = this.downloadLinks[device].link;
      window.open(this.selectedLink, "_blank");
    },
  },
};
</script>
